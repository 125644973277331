import BaseModule from './utils/BaseModule'
import store from '@/store'
import { baseconfig } from "@/config.js";
class BaseManager extends BaseModule {
  constructor() {
    super()
  }

  // 上传文件
  uploadfile (param,config={}) {
    let paras = {
      files: param
    }
    return this.upload('common/sysFile/uploads', paras,config)
  }
  //导出文件
  exportfile (param, requstUrl) {
    const paras = {
      ...param,
      page: 1,
      page_size: 2147483647,
      access_token: this.access_token()
    }
    console.log(`${this.baseURL}${requstUrl}?${this.qs.stringify(paras)}`)
    window.open(`${this.baseURL}${requstUrl}?${this.qs.stringify(paras)}`)
  }

  /**
   * 系统菜单
   */
  //获取系统菜单列表
  getsystemmenulist () {
    return this.post('/sys/menu/sys_treeList')
  }
  
  // POST    添加系统菜单
  addSystemMenuInfo (param) {
    const paras = {
	  parentId: param.parentId,
	  name: param.name,
	  url: param.url,
	  perms: param.perms,
	  type: param.type,
	  icon: param.icon,
	  orderNum: param.orderNum
    }
    return this.post('/sys/menu/save', paras)
  }
  
  // POST    编辑系统菜单
  editSystemMenuInfo (param) {
    const paras = {
  	  parentId: param.parentId,
  	  name: param.name,
  	  url: param.url,
  	  perms: param.perms,
  	  type: param.type,
  	  icon: param.icon,
  	  orderNum: param.orderNum,
	  menuId: param.menuId
    }
    return this.post('/sys/menu/update', paras)
  }
  
  // POST    删除系统菜单
  delSystemMenuInfo (param) {
    const paras = {
  	  id: param.id,
    }
    return this.post('/sys/menu/remove', paras)
  }

}

export default new BaseManager()